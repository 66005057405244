import { render, staticRenderFns } from "./MyHeader.vue?vue&type=template&id=5c98b750&scoped=true"
import script from "./MyHeader.vue?vue&type=script&lang=js"
export * from "./MyHeader.vue?vue&type=script&lang=js"
import style0 from "./MyHeader.vue?vue&type=style&index=0&id=5c98b750&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_css-loader@6.11.0_webpack@5.96.1__lodash@4.17.21__2dnwv33wcshjexuxr5fqoigkvy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c98b750",
  null
  
)

export default component.exports